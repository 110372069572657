.unsupported-browser-page .box {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 20px;
    min-height: 372px;
    max-width: 606px;
    margin: 13px auto 20px auto;
    font-family: Rota;
}

.unsupported-browser-page .gate-page .download-app {
    /*display: flex;*/
    flex-direction: column;
    max-width: 606px;
    margin: 20px auto 20px auto;
    padding: 0 40px 0 40px;
    align-items: center;
    justify-content: space-between;
}

.unsupported-browser-page section.title {
    display: flex;
    justify-content: center;
}

.unsupported-browser-page section.title h1 {
    font-family: Rota;
    font-weight: bold;
    font-size: 40px;
    line-height: 44px;
    text-align: center;
    color: #001315;
    margin-bottom: 20px;
}

.unsupported-browser-page section.supported-browsers p {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #001315;
    margin-bottom: 20px;
}

.unsupported-browser-page section.supported-browsers .browser-box {
    background-color: #F2F3F3;
    max-width: 398px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    align-items: center;
    border-radius: 12px;
    cursor: pointer;
}

.unsupported-browser-page section.supported-browsers .browser-box .cta-data {
    display: flex;
    align-items: center;
}

.unsupported-browser-page section.supported-browsers .browser-box .cta-data h2 {
    font-family: Rota;
    font-weight: 600;
    margin-bottom: 0;
    margin-left: 15px;
    font-size: 18px;
    line-height: 22px;
}

@media (max-width: 1024px) {
    .unsupported-browser-page section.title h1 {
        font-size: 36px;
        margin-top: 40px;
    }

    .unsupported-browser-page section.about-us p {
        font-size: 18px;
        line-height: 20px;
    }
}

.unsupported-browser-page .landing-color-orange {
    color: #FF6A39 !important;
    font-weight: bold !important;
}

.unsupported-browser-page .subtitle-promotion {
    margin-top: 20px;
    text-align: center;
}

.unsupported-browser-page .subtitle-promotion h3 {
    margin-bottom: 0;
}

.unsupported-browser-page .subtitle-promotion h3.subtitle-promotion-value {
    font-weight: bold;
    color: #FF6A39;
}

.unsupported-browser-page .gate-page {
    border-radius: 12px;
    border: 1px solid #E5E7E8;
    margin-top: 40px;
}

.unsupported-browser-page .gate-page .download-app p {
    font-family: Rota;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #001315;
    margin-bottom: 20px;
}

.unsupported-browser-page .gate-page .download-app .apps-button .app-store-icon {
    background-image: url("../../shared/assets/icons/app-store-icon-black-background.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    width: 202px;
    height: 58px;
    /*display: inline-flex;*/
    cursor: pointer;
}

.unsupported-browser-page .gate-page .download-app .apps-button .google-play-icon {
    background-image: url("../../shared/assets/icons/google-play-icon-black-background.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    width: 202px;
    height: 58px;
    /*display: inline-flex;*/
    cursor: pointer;
}

.unsupported-browser-page .gate-page .spacer {
    width: 10px;
}


.unsupported-browser-page .gate-page .download-app .apps-button.vertical-buttons {
    display: block;
    text-align: center;
}

.unsupported-browser-page .gate-page .download-app .apps-button.vertical-buttons a {
    margin: 0;
}

.unsupported-browser-page .gate-page .download-app.download-app-no-padding {
    padding: 0;
}

.unsupported-browser-page .gate-page .download-app.download-app-no-padding .paragraph-slim {
    padding: 0 50px;
    font-size: 22px;
}

.unsupported-browser-page .boxes {
    margin-top: 20px;
}

.unsupported-browser-page .box-number {
    background: #FFFFFF;
    border-radius: 12px;
    margin-bottom: 20px;
    display: flex;
    /*align-items: center;*/
}

.unsupported-browser-page .box-number .circle {
    border-radius: 100%;
    display: block;
    width: 50px;
    height: 50px;
    margin-right: 20px;
    /*display: inline-flex;*/
    align-items: center;
    justify-content: center;
    font-family: 'Rota';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 110%;
    text-align: center;
    color: #FFFFFF;
}

.unsupported-browser-page .box-number .circle span {
    /*height: 80px;*/
    width: 50px;
    display: block;
    padding-top: 8px;
}

.unsupported-browser-page .box-number .description {
    font-family: 'Rota';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
}

.unsupported-browser-page .box-number .circle.one {
    background: #891F00;
}

.unsupported-browser-page .box-number .circle.two {
    background: #BB2900;
}

.unsupported-browser-page .box-number .circle.three {
    background: #EB3300;
}

.unsupported-browser-page .box-number .circle.four {
    background: #FF6A39;
}

.unsupported-browser-page .box-number .circle.five {
    background: #FF916C;
}

.unsupported-browser-page .box-number .description .apps-button .app-store-icon {
    background-image: url("../../shared/assets/icons/app-store-icon-white.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    width: 240px;
    height: 46px;
    display: inline-flex;
    cursor: pointer;
}

.unsupported-browser-page .box-number .description .apps-button .google-play-icon {
    background-image: url("../../shared/assets/icons/google-play-icon-white.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    width: 240px;
    height: 47px;
    display: inline-flex;
    cursor: pointer;
}

.unsupported-browser-page .box-number .description .apps-button.vertical-buttons {
    display: block;
    text-align: center;
}

.unsupported-browser-page .box-number .apps-button.vertical-buttons a {
    margin: 0;
}

.unsupported-browser-page .gift {
    background: rgba(255, 106, 57, 0.1);
    padding: 20px;
    border-radius: 12px;
    color: #FF6A39;
    font-size: 20px;
    text-align: center;
}

.unsupported-browser-page .gift .subtitle-promotion-value {
    font-weight: bold;
}

.unsupported-browser-page .gift .gift-icon {
    background-image: url("../../shared/assets/icons/gift.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    width: 36px;
    height: 42px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
